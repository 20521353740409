import React from "react";

const EditSvgIcon = ({
  color,
  width = "16",
  height = "16",
  onClick = () => {},
}) => {
  if (!color) {
    color = "#ff5400";
  }
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M16 3.12205L12.8781 0.00012207L10.7148 2.16338L13.8368 5.28544L16 3.12205Z"
        fill={color}
      />
      <path
        d="M3.2002 12.801L6.6343 12.4888L12.5439 6.57922L9.4218 3.45715L3.51223 9.36672L3.2002 12.801Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4 15.9998H0V14.3999H10.4V15.9998Z"
        fill={color}
      />
    </svg>
  );
};

export default EditSvgIcon;
